/* eslint-disable */
// @ts-ignore
import BigNumber from 'bignumber.js';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import { GetSubscription, Request } from 'core/api/query-types';

import * as Types from '../../schema';
import { VaultListFragmentDoc } from './vault-list.fragment.generated';

export type GetVaultsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetVaultsQuery = {
  __typename?: 'Query';
  vaults: Array<{
    __typename?: 'VaultType';
    uid: string;
    name: string;
    symbol: string;
    slug: string;
    cover?: string | null;
    avatar?: string | null;
    riskProfile: Types.RiskProfile;
    marketCapSegment?: Array<number> | null;
    managerSentiment?: Types.ManagerSentiment | null;
    shortDescription?: string | null;
    managerParticipation?: Types.ManagerParticipation | null;
    tags?: Array<Types.VaultTag> | null;
    managerFocusTime?: Types.ManagerFocusTime | null;
    updatedAt: string;
    createdAt: string;
    chains: Array<{ __typename?: 'VaultChainType'; isParent: boolean; chainId: number; name: string; address: string }>;
    manager: {
      __typename?: 'ManagerType';
      account: {
        __typename?: 'AccountType';
        name?: string | null;
        address: string;
        twitter?: string | null;
        avatar?: string | null;
      };
    };
    stats: {
      __typename?: 'VaultStatsType';
      aum: BigNumber;
      depositors: BigNumber;
      roiWeek?: BigNumber | null;
      roiDay?: BigNumber | null;
      roiTotal?: BigNumber | null;
    };
  }>;
};

export const GetVaultsDocument = gql`
  query GetVaults {
    vaults {
      ...VaultList
    }
  }
  ${VaultListFragmentDoc}
`;

export function GetVaults<R extends GetVaultsQuery, V extends GetVaultsQueryVariables>(
  request: Request<R, V>,
  variables?: V,
  headers?: HeadersInit
): Promise<R> {
  return request(GetVaultsDocument, variables, headers);
}
