import BigNumber from 'bignumber.js';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * Implement the DateTime<Utc> scalar
   *
   * The input/output is a string in RFC3339 format.
   */
  DateTime: { input: Date; output: Date };
  Decimal: { input: BigNumber; output: BigNumber };
  /** A scalar that can represent any JSON value. */
  JSON: { input: string; output: string };
  /**
   * ISO 8601 combined date and time without timezone.
   *
   * # Examples
   *
   * * `2015-07-01T08:59:60.123`,
   */
  NaiveDateTime: { input: string; output: string };
  Upload: { input: File; output: File };
};

export type AccountType = {
  __typename?: 'AccountType';
  address: Scalars['String']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  chain: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  experience: Scalars['Float']['output'];
  experienceSpins: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** Referral code is used to track the referrer */
  referralCode?: Maybe<Scalars['String']['output']>;
  /** Referrer code can be used to refer other users */
  referrerCode: Scalars['String']['output'];
  /** Referrer provider is the platform where the user was referred from */
  referrerProvider?: Maybe<ReferrerProvider>;
  rewards: Array<RewardType>;
  slug: Scalars['String']['output'];
  twitter?: Maybe<Scalars['String']['output']>;
};

export type AddTradeMemoInput = {
  action: ExecutorAction;
  blockNumber: Scalars['Int']['input'];
  chain: Scalars['String']['input'];
  integration: ExecutorIntegration;
  message: Scalars['String']['input'];
  vaultUid: Scalars['String']['input'];
};

export type AssetAllocation = {
  __typename?: 'AssetAllocation';
  allocation: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
};

export type AssetCapDistribution = {
  __typename?: 'AssetCapDistribution';
  asset: AssetType;
  portion: Scalars['Decimal']['output'];
};

export type AssetDataset = {
  __typename?: 'AssetDataset';
  close: Scalars['Int']['output'];
  high: Scalars['Int']['output'];
  low: Scalars['Int']['output'];
  open: Scalars['Int']['output'];
  time: Scalars['Int']['output'];
};

export enum AssetDatasetAggregation {
  D1 = 'D1',
  H1 = 'H1',
  H4 = 'H4',
  M1 = 'M1',
  M5 = 'M5',
  M15 = 'M15',
}

export enum AssetDatasetChain {
  Arbitrum = 'ARBITRUM',
  Optimism = 'OPTIMISM',
}

export type AssetDatasetEventInput = {
  aggregate: AssetDatasetAggregation;
  fromSymbol: Scalars['String']['input'];
  toSymbol: Scalars['String']['input'];
};

export type AssetDatasetInput = {
  aggregate: AssetDatasetAggregation;
  fromSymbol: Scalars['String']['input'];
  fromTime: Scalars['Int']['input'];
  toSymbol: Scalars['String']['input'];
  toTime: Scalars['Int']['input'];
};

export type AssetPairInput = {
  chain: AssetDatasetChain;
  firstAsset: Scalars['String']['input'];
  secondAsset: Scalars['String']['input'];
};

export type AssetPairPriceType = {
  __typename?: 'AssetPairPriceType';
  firstAsset: AssetType;
  price?: Maybe<Scalars['Decimal']['output']>;
  priceChange24H?: Maybe<AssetPriceChange>;
  secondAsset: AssetType;
};

export type AssetPriceChange = {
  __typename?: 'AssetPriceChange';
  close: Scalars['Decimal']['output'];
  high: Scalars['Decimal']['output'];
  low: Scalars['Decimal']['output'];
  open: Scalars['Decimal']['output'];
};

export type AssetType = {
  __typename?: 'AssetType';
  address: Scalars['String']['output'];
  chain: Scalars['String']['output'];
  chainId: Scalars['Int']['output'];
  circulatingSupply?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  decimals?: Maybe<Scalars['Int']['output']>;
  deprecated: Scalars['Boolean']['output'];
  hardDeprecated: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  marketCap?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Decimal']['output']>;
  priceChangeAmount?: Maybe<Scalars['Decimal']['output']>;
  priceChangePercentage?: Maybe<Scalars['Decimal']['output']>;
  symbol: Scalars['String']['output'];
  totalSupply?: Maybe<Scalars['String']['output']>;
  type: AssetTypeEnum;
};

export enum AssetTypeEnum {
  Erc_20 = 'ERC_20',
  Gmx = 'GMX',
  SnxPerpsV2 = 'SNX_PERPS_V2',
  Unknown = 'UNKNOWN',
}

export type AssetVaultType = {
  __typename?: 'AssetVaultType';
  asset: AssetType;
  vault: VaultType;
};

export type AumDataset = {
  __typename?: 'AumDataset';
  key: Scalars['NaiveDateTime']['output'];
  value: Scalars['Decimal']['output'];
};

export type AuthType = {
  __typename?: 'AuthType';
  accessToken: Scalars['String']['output'];
  account?: Maybe<AccountType>;
  address: Scalars['String']['output'];
};

export enum ChartDatasetRange {
  All = 'ALL',
  OneDay = 'ONE_DAY',
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
}

export enum DateRangeInput {
  OneDay = 'ONE_DAY',
  OneWeek = 'ONE_WEEK',
}

export type DepositAndVault = {
  __typename?: 'DepositAndVault';
  deposit: DepositType;
  vault: VaultType;
};

export type DepositChaintDistribution = {
  __typename?: 'DepositChaintDistribution';
  chain: Scalars['String']['output'];
  portion: Scalars['Decimal']['output'];
};

export type DepositHistoryType = {
  __typename?: 'DepositHistoryType';
  amount: Scalars['Decimal']['output'];
  createdAt: Scalars['String']['output'];
  depositor: Scalars['String']['output'];
  shares: Scalars['Decimal']['output'];
};

export type DepositType = {
  __typename?: 'DepositType';
  createdAt: Scalars['String']['output'];
  depositedAmount: Scalars['Decimal']['output'];
  depositor: Scalars['String']['output'];
  entryPrice: Scalars['Decimal']['output'];
  history: Array<DepositHistoryType>;
  pAndL: Scalars['Decimal']['output'];
  roi: Scalars['Decimal']['output'];
  shares: Scalars['Decimal']['output'];
  sharesValue: Scalars['Decimal']['output'];
  stake: Scalars['Decimal']['output'];
};

export type DepositUnitType = {
  __typename?: 'DepositUnitType';
  deposit: DepositType;
  depositHistory: DepositHistoryType;
};

export type DepositorViewType = {
  __typename?: 'DepositorViewType';
  cumulativeAum: Scalars['Decimal']['output'];
  cumulativeRoi: Scalars['Decimal']['output'];
  vaults: Array<VaultType>;
};

export type DepositorsDataset = {
  __typename?: 'DepositorsDataset';
  key: Scalars['NaiveDateTime']['output'];
  value?: Maybe<Scalars['Decimal']['output']>;
};

export type Deposits = {
  __typename?: 'Deposits';
  items: Array<DepositType>;
  numItems: Scalars['Int']['output'];
  numPages: Scalars['Int']['output'];
};

export enum EntityType {
  Account = 'ACCOUNT',
  Cover = 'COVER',
  Vault = 'VAULT',
}

export type Erc2612PermitInput = {
  deadline: Scalars['Decimal']['input'];
  owner: Scalars['String']['input'];
  r: Scalars['String']['input'];
  s: Scalars['String']['input'];
  token: Scalars['String']['input'];
  v: Scalars['String']['input'];
  value: Scalars['Decimal']['input'];
};

export enum ExecutorAction {
  KwentaDeposit = 'KWENTA_DEPOSIT',
  KwentaSubmitCloseOrder = 'KWENTA_SUBMIT_CLOSE_ORDER',
  KwentaSubmitOrder = 'KWENTA_SUBMIT_ORDER',
  KwentaWithdraw = 'KWENTA_WITHDRAW',
  PerpLongDecrease = 'PERP_LONG_DECREASE',
  PerpLongIncrease = 'PERP_LONG_INCREASE',
  PerpShortDecrease = 'PERP_SHORT_DECREASE',
  PerpShortIncrease = 'PERP_SHORT_INCREASE',
  Swap = 'SWAP',
  Unknown = 'UNKNOWN',
}

export enum ExecutorIntegration {
  Gmx = 'GMX',
  Kwenta = 'KWENTA',
  Unknown = 'UNKNOWN',
  ZeroX = 'ZERO_X',
}

export type GetQuoteInput = {
  buyToken: Scalars['String']['input'];
  chainId: Scalars['Int']['input'];
  sellAmount: Scalars['String']['input'];
  sellToken: Scalars['String']['input'];
  slippagePercentage?: InputMaybe<Scalars['String']['input']>;
  taker: Scalars['String']['input'];
};

export type GetTradingMemoInput = {
  fromDate: Scalars['DateTime']['input'];
  toDate: Scalars['DateTime']['input'];
  vaultUid: Scalars['String']['input'];
};

export type GmxActionType = {
  __typename?: 'GmxActionType';
  action: ExecutorAction;
  chain: Scalars['String']['output'];
  chainId: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inputToken: AssetType;
  inputTokenAmount: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  outputToken: AssetType;
  outputTokenAmount: Scalars['String']['output'];
  transaction: Scalars['String']['output'];
  unitPrice: Scalars['String']['output'];
};

export type ImageUploadInput = {
  /**
   * Entity identifier, may have different meaning for different entity type:
   * Vault   - UID (VaultId)
   * Account - Address of account
   */
  entityId: Scalars['String']['input'];
  /** Entity type */
  entityType: EntityType;
};

export type KwentaActionDepositType = {
  __typename?: 'KwentaActionDepositType';
  action: ExecutorAction;
  chain: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inputToken: AssetType;
  inputTokenAmount: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  perpMarket: AssetType;
  transaction: Scalars['String']['output'];
  wrapper: Scalars['String']['output'];
};

export type KwentaActionSubmitCloseOrderType = {
  __typename?: 'KwentaActionSubmitCloseOrderType';
  action: ExecutorAction;
  chain: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  desiredFillPrice: Scalars['String']['output'];
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  perpMarket: AssetType;
  transaction: Scalars['String']['output'];
  wrapper: Scalars['String']['output'];
};

export type KwentaActionSubmitOrderType = {
  __typename?: 'KwentaActionSubmitOrderType';
  action: ExecutorAction;
  chain: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  desiredFillPrice: Scalars['String']['output'];
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  perpMarket: AssetType;
  sizeDelta: Scalars['String']['output'];
  transaction: Scalars['String']['output'];
  wrapper: Scalars['String']['output'];
};

export type KwentaActionWithdrawType = {
  __typename?: 'KwentaActionWithdrawType';
  action: ExecutorAction;
  chain: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  outputToken: AssetType;
  outputTokenAmount: Scalars['String']['output'];
  perpMarket: AssetType;
  transaction: Scalars['String']['output'];
  wrapper: Scalars['String']['output'];
};

export type LinkTwitterInput = {
  code: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type LoginSignatureInput = {
  /** Referrer information (Optional) */
  referrer?: InputMaybe<ReferrerInput>;
  /** Signature for provided timestamp */
  signature: Scalars['String']['input'];
  /** Timestamp used for the signature */
  timestamp: Scalars['String']['input'];
};

export enum ManagerFocusTime {
  Long = 'LONG',
  Medium = 'MEDIUM',
  Short = 'SHORT',
}

export enum ManagerParticipation {
  Active = 'ACTIVE',
  Moderate = 'MODERATE',
  Sidelined = 'SIDELINED',
}

export enum ManagerSentiment {
  Bearish = 'BEARISH',
  Bullish = 'BULLISH',
  Neutral = 'NEUTRAL',
}

export enum ManagerStrategy {
  Fundamentals = 'FUNDAMENTALS',
  MarketTiming = 'MARKET_TIMING',
  NarrativeTrades = 'NARRATIVE_TRADES',
  NewListings = 'NEW_LISTINGS',
  Other = 'OTHER',
  ScalpTrading = 'SCALP_TRADING',
  SwingTrading = 'SWING_TRADING',
  TechnicalAnalysis = 'TECHNICAL_ANALYSIS',
}

export type ManagerType = {
  __typename?: 'ManagerType';
  account: AccountType;
  deposit?: Maybe<DepositType>;
};

export type ManagerViewType = {
  __typename?: 'ManagerViewType';
  cumulativeAum: Scalars['Decimal']['output'];
  cumulativeAum7DChange: Scalars['Decimal']['output'];
  cumulativeDepositors: Scalars['Int']['output'];
  cumulativeDepositors7DChange: Scalars['Int']['output'];
  cumulativeRoi: Scalars['Decimal']['output'];
  cumulativeRoiDataset: Array<RoiDataset>;
  vaults: Array<VaultType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addTradeMemo: Scalars['Boolean']['output'];
  linkTwitterToAccount: AccountType;
  /** Exchange signature to token that can be used to authorize user */
  loginWithSignature: AuthType;
  /** Send deposit without sync request */
  relayDeposit: Scalars['String']['output'];
  /** Queue deposit and make sync request */
  relayQueueDepositAndSync: Scalars['String']['output'];
  /** Queue withdraw and make sync request */
  relayQueueWithdrawAndSync: Scalars['String']['output'];
  /** Remove deposit from queue */
  relayRemoveQueuedDeposit: Scalars['String']['output'];
  /** Remove withdraw from queue */
  relayRemoveQueuedWithdraw: Scalars['String']['output'];
  /** Make withdraw without sync request */
  relayWithdraw: Scalars['String']['output'];
  requestImageUpload: Scalars['String']['output'];
  revealReward: RewardType;
  /** Using Tenderly generate link for transaction simulation */
  simulateTransaction: Scalars['String']['output'];
  spinExperience: Scalars['Float']['output'];
  updateAccount: AccountType;
  updateNotificationsStatus: Array<NotificationType>;
  updateVault: VaultType;
  uploadImage: Scalars['String']['output'];
};

export type MutationAddTradeMemoArgs = {
  input: AddTradeMemoInput;
};

export type MutationLinkTwitterToAccountArgs = {
  input: LinkTwitterInput;
};

export type MutationLoginWithSignatureArgs = {
  input: LoginSignatureInput;
};

export type MutationRelayDepositArgs = {
  input: RelayDepositInput;
};

export type MutationRelayQueueDepositAndSyncArgs = {
  input: RelayQueueDepositAndSyncInput;
};

export type MutationRelayQueueWithdrawAndSyncArgs = {
  input: RelayQueueWithdrawAndSyncInput;
};

export type MutationRelayRemoveQueuedDepositArgs = {
  input: RelayRemoveQueuedDepositInput;
};

export type MutationRelayRemoveQueuedWithdrawArgs = {
  input: RelayRemoveQueuedWithdrawInput;
};

export type MutationRelayWithdrawArgs = {
  input: RelayWithdrawInput;
};

export type MutationRequestImageUploadArgs = {
  input: ImageUploadInput;
};

export type MutationSimulateTransactionArgs = {
  input: SimulateTransactionInput;
};

export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};

export type MutationUpdateNotificationsStatusArgs = {
  input: UpdateNotificationStatusInput;
};

export type MutationUpdateVaultArgs = {
  input: UpdateVaultInput;
};

export type MutationUploadImageArgs = {
  file: Scalars['Upload']['input'];
  input: ImageUploadInput;
};

export enum NotificationStatusEnum {
  Pending = 'PENDING',
  Seen = 'SEEN',
}

export type NotificationType = {
  __typename?: 'NotificationType';
  context?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: NotificationStatusEnum;
  type: NotificationTypeEnum;
};

export enum NotificationTypeEnum {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW',
}

export type NotificationsFilterInput = {
  status?: InputMaybe<NotificationStatusEnum>;
  type?: InputMaybe<NotificationTypeEnum>;
  vault?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationsInput = {
  filter?: InputMaybe<NotificationsFilterInput>;
  page?: InputMaybe<PageInput>;
};

export type NotificationsType = {
  __typename?: 'NotificationsType';
  items: Array<NotificationType>;
  numItems: Scalars['Int']['output'];
  numPages: Scalars['Int']['output'];
};

export type OrganizationType = {
  __typename?: 'OrganizationType';
  createdAt: Scalars['String']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type PageInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PortfolioType = {
  __typename?: 'PortfolioType';
  assetCapDistribution: Array<AssetCapDistribution>;
  /** Get distribute of assets across chains */
  assetChainDistribution: Array<DepositChaintDistribution>;
  /** Get all deposits of the account */
  deposits: Array<DepositAndVault>;
  /** Get total amount of deposits across all vaults in USD */
  depositsAmount: Scalars['Decimal']['output'];
  /** Get total number of active deposits */
  depositsCount: Scalars['Int']['output'];
  /** Total PnL of all deposits across all vaults in USD */
  pAndL: Scalars['Decimal']['output'];
  /** Total ROI of all deposits across all vaults */
  roi: Scalars['Decimal']['output'];
  /** Get ROI of all deposits across all vaults in specified range */
  roiAgainst: Scalars['Decimal']['output'];
  roiDataset: Array<RoiDataset>;
  /** Get ROI of all deposits across all vaults in specified range against specified asset */
  roiDatasetAgainst: Array<RoiDataset>;
  /** Get distribution of sentiments across all vaults where user has deposit */
  vaultSentimentDistribution: Array<SentimentDistribution>;
};

export type PortfolioTypeRoiAgainstArgs = {
  symbol: Scalars['String']['input'];
};

export type PortfolioTypeRoiDatasetAgainstArgs = {
  symbol: Scalars['String']['input'];
};

export type ProtocolFee = {
  __typename?: 'ProtocolFee';
  amount: Scalars['Decimal']['output'];
  token: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  account: AccountType;
  assetDataset: Array<AssetDataset>;
  assetPairPrice: AssetPairPriceType;
  assets: Array<AssetType>;
  aumDataset: Array<AumDataset>;
  deposit?: Maybe<DepositType>;
  depositRoiDataset: Array<RoiDataset>;
  depositorsDataset: Array<DepositorsDataset>;
  deposits: Deposits;
  featuredVaults: Array<VaultType>;
  newVaults: Array<VaultType>;
  notifications: NotificationsType;
  organizations: Array<OrganizationType>;
  /** Returns portfolio for given address */
  portfolio: PortfolioType;
  quote: SwapQuoteType;
  referrals: Array<ReferralType>;
  roiDataset: Array<RoiDataset>;
  tradingMemo: Array<TradingMemoType>;
  twitterAuthUrl: Scalars['String']['output'];
  validateVaultName: Scalars['Boolean']['output'];
  vault: VaultType;
  vaultActions: VaultActions;
  vaultBySlug: VaultType;
  vaultTransfers: VaultTransfers;
  vaults: Array<VaultType>;
  vaultsByLatestDeposit: Array<VaultType>;
  vaultsByRoi: Array<VaultType>;
  version: Scalars['String']['output'];
  viewer: ViewerType;
};

export type QueryAccountArgs = {
  address: Scalars['String']['input'];
};

export type QueryAssetDatasetArgs = {
  input: AssetDatasetInput;
};

export type QueryAssetPairPriceArgs = {
  input: AssetPairInput;
};

export type QueryAumDatasetArgs = {
  dateRange?: InputMaybe<ChartDatasetRange>;
  uid: Scalars['String']['input'];
};

export type QueryDepositArgs = {
  depositor: Scalars['String']['input'];
  vault: Scalars['String']['input'];
};

export type QueryDepositRoiDatasetArgs = {
  dateRange?: InputMaybe<ChartDatasetRange>;
  depositor: Scalars['String']['input'];
  vault: Scalars['String']['input'];
};

export type QueryDepositorsDatasetArgs = {
  dateRange?: InputMaybe<ChartDatasetRange>;
  uid: Scalars['String']['input'];
};

export type QueryDepositsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  vault: Scalars['String']['input'];
};

export type QueryFeaturedVaultsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryNewVaultsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryNotificationsArgs = {
  input?: InputMaybe<NotificationsInput>;
};

export type QueryPortfolioArgs = {
  address: Scalars['String']['input'];
  range: DateRangeInput;
};

export type QueryQuoteArgs = {
  input: GetQuoteInput;
};

export type QueryReferralsArgs = {
  address: Scalars['String']['input'];
};

export type QueryRoiDatasetArgs = {
  dateRange?: InputMaybe<ChartDatasetRange>;
  uid: Scalars['String']['input'];
};

export type QueryTradingMemoArgs = {
  input: GetTradingMemoInput;
};

export type QueryTwitterAuthUrlArgs = {
  redirectUri: Scalars['String']['input'];
};

export type QueryValidateVaultNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryVaultArgs = {
  uid: Scalars['String']['input'];
};

export type QueryVaultActionsArgs = {
  input: VaultActionsInput;
};

export type QueryVaultBySlugArgs = {
  slug: Scalars['String']['input'];
};

export type QueryVaultTransfersArgs = {
  input: VaultTransfersInput;
};

export type QueryVaultsByLatestDepositArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryVaultsByRoiArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  range: DateRangeInput;
};

export type QueryViewerArgs = {
  address: Scalars['String']['input'];
};

export type ReferralType = {
  __typename?: 'ReferralType';
  points: Scalars['Float']['output'];
  referral: AccountType;
};

export type ReferrerInput = {
  referrerCode: Scalars['String']['input'];
  referrerProvider: ReferrerProvider;
};

export enum ReferrerProvider {
  Impact = 'IMPACT',
  Valio = 'VALIO',
}

export type RelayDepositInput = {
  depositParams: RelayDepositStructInput;
  depositPermit: Scalars['String']['input'];
  erc2612Permit: Erc2612PermitInput;
};

export type RelayDepositStructInput = {
  deadline: Scalars['Decimal']['input'];
  depositAmount: Scalars['Decimal']['input'];
  maxUnitPrice: Scalars['Decimal']['input'];
  permitAmountForAllFees: Scalars['Decimal']['input'];
  referrer: Scalars['String']['input'];
  signerNonce: Scalars['Decimal']['input'];
  tokenId: Scalars['Decimal']['input'];
  vault: Scalars['String']['input'];
};

export type RelayQueueDepositAndSyncInput = {
  erc2612Permit: Erc2612PermitInput;
  queueDepositAndSyncParams: RelayQueueDepositAndSyncStructInput;
  queueDepositAndSyncPermit: Scalars['String']['input'];
};

export type RelayQueueDepositAndSyncStructInput = {
  depositAmount: Scalars['Decimal']['input'];
  expiry: Scalars['Decimal']['input'];
  keeperFee: Scalars['Decimal']['input'];
  lzSyncFees: Array<Scalars['Decimal']['input']>;
  maxUnitPrice: Scalars['Decimal']['input'];
  permitAmountForAllFees: Scalars['Decimal']['input'];
  referrer: Scalars['String']['input'];
  signerNonce: Scalars['Decimal']['input'];
  tokenId: Scalars['Decimal']['input'];
  vault: Scalars['String']['input'];
};

export type RelayQueueWithdrawAndSyncInput = {
  erc2612Permit: Erc2612PermitInput;
  queueWithdrawAndSyncParams: RelayQueueWithdrawAndSyncStructInput;
  queueWithdrawAndSyncPermit: Scalars['String']['input'];
};

export type RelayQueueWithdrawAndSyncStructInput = {
  expiry: Scalars['Decimal']['input'];
  keeperFee: Scalars['Decimal']['input'];
  lzSyncFees: Array<Scalars['Decimal']['input']>;
  lzWithdrawFeesTotal: Scalars['Decimal']['input'];
  minUnitPrice: Scalars['Decimal']['input'];
  shares: Scalars['Decimal']['input'];
  signerNonce: Scalars['Decimal']['input'];
  tokenId: Scalars['Decimal']['input'];
  vault: Scalars['String']['input'];
};

export type RelayRemoveQueuedDepositInput = {
  erc2612Permit: Erc2612PermitInput;
  removeQueuedDepositParams: RelayRemoveQueuedDepositStructInput;
  removeQueuedDepositPermit: Scalars['String']['input'];
};

export type RelayRemoveQueuedDepositStructInput = {
  deadline: Scalars['Decimal']['input'];
  index: Scalars['Decimal']['input'];
  permitAmountForAllFees: Scalars['Decimal']['input'];
  signerNonce: Scalars['Decimal']['input'];
  vault: Scalars['String']['input'];
};

export type RelayRemoveQueuedWithdrawInput = {
  erc2612Permit: Erc2612PermitInput;
  removeQueuedWithdrawParams: RelayRemoveQueuedWithdrawStructInput;
  removeQueuedWithdrawPermit: Scalars['String']['input'];
};

export type RelayRemoveQueuedWithdrawStructInput = {
  deadline: Scalars['Decimal']['input'];
  index: Scalars['Decimal']['input'];
  signerNonce: Scalars['Decimal']['input'];
  vault: Scalars['String']['input'];
};

export type RelayWithdrawInput = {
  erc2612Permit: Erc2612PermitInput;
  withdrawParams: RelayWithdrawStructInput;
  withdrawPermit: Scalars['String']['input'];
};

export type RelayWithdrawStructInput = {
  deadline: Scalars['Decimal']['input'];
  lzWithdrawFees: Array<Scalars['Decimal']['input']>;
  minUnitPrice: Scalars['Decimal']['input'];
  shares: Scalars['Decimal']['input'];
  signerNonce: Scalars['Decimal']['input'];
  tokenId: Scalars['Decimal']['input'];
  vault: Scalars['String']['input'];
};

export enum RewardEnumType {
  Common = 'COMMON',
  Legendary = 'LEGENDARY',
  Mythic = 'MYTHIC',
  Rare = 'RARE',
}

export type RewardType = {
  __typename?: 'RewardType';
  claimedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  revealedAt?: Maybe<Scalars['String']['output']>;
  rewardType?: Maybe<RewardEnumType>;
};

export enum RiskProfile {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export type RoiDataset = {
  __typename?: 'RoiDataset';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SentimentDistribution = {
  __typename?: 'SentimentDistribution';
  count: Scalars['Decimal']['output'];
  portion: Scalars['Decimal']['output'];
  sentiment: ManagerSentiment;
};

export type SimulateTransactionInput = {
  from: Scalars['String']['input'];
  gas: Scalars['Int']['input'];
  gasPrice: Scalars['Int']['input'];
  input: Scalars['String']['input'];
  networkId: Scalars['Int']['input'];
  to: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type SubscriptionRoot = {
  __typename?: 'SubscriptionRoot';
  accountCreated: AccountType;
  accountUpdated: AccountType;
  assetCreated: AssetType;
  assetDataset: AssetDataset;
  assetLinked: AssetVaultType;
  assetPairPrice: AssetPairPriceType;
  assetUpdated: AssetType;
  deposit: DepositUnitType;
  notification: NotificationType;
  ping: Scalars['String']['output'];
  referral: ReferralType;
  reward: RewardType;
  vaultAction: VaultActionUnion;
  vaultChain: VaultChainType;
  vaultCreated: VaultType;
  vaultDeleted: VaultType;
  vaultUpdated: VaultType;
  withdraw: WithdrawUnitType;
};

export type SubscriptionRootAssetDatasetArgs = {
  input: AssetDatasetEventInput;
};

export type SubscriptionRootAssetPairPriceArgs = {
  input: AssetPairInput;
};

export type SubscriptionRootDepositArgs = {
  uid: Scalars['String']['input'];
};

export type SubscriptionRootVaultActionArgs = {
  filter?: InputMaybe<VaultActionsFilterInput>;
  vault: Scalars['String']['input'];
};

export type SubscriptionRootVaultChainArgs = {
  uid: Scalars['String']['input'];
};

export type SubscriptionRootVaultDeletedArgs = {
  uid?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionRootVaultUpdatedArgs = {
  uid?: InputMaybe<Scalars['String']['input']>;
};

export type SubscriptionRootWithdrawArgs = {
  uid: Scalars['String']['input'];
};

export type SwapQuoteLiquidityAvailableType = {
  __typename?: 'SwapQuoteLiquidityAvailableType';
  buyAmount: Scalars['String']['output'];
  buyToken: Scalars['String']['output'];
  data: Scalars['String']['output'];
  estimatedGas?: Maybe<Scalars['String']['output']>;
  gasPrice: Scalars['String']['output'];
  liquidityAvailable: Scalars['Boolean']['output'];
  minBuyAmount: Scalars['String']['output'];
  protocolFee?: Maybe<ProtocolFee>;
  sellAmount: Scalars['String']['output'];
  sellToken: Scalars['String']['output'];
  to: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SwapQuoteType = {
  __typename?: 'SwapQuoteType';
  data?: Maybe<SwapQuoteLiquidityAvailableType>;
  liquidityAvailable: Scalars['Boolean']['output'];
};

export type TradingMemoType = {
  __typename?: 'TradingMemoType';
  action: VaultActionUnion;
  createdAt: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type UpdateAccountInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNotificationStatusInput = {
  beforeDate: Scalars['DateTime']['input'];
  type?: InputMaybe<NotificationTypeEnum>;
  vault?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVaultInput = {
  assetWatchlist?: InputMaybe<Array<Scalars['String']['input']>>;
  fullDescription?: InputMaybe<Scalars['String']['input']>;
  managerFocusTime?: InputMaybe<ManagerFocusTime>;
  managerParticipation?: InputMaybe<ManagerParticipation>;
  managerSentiment?: InputMaybe<ManagerSentiment>;
  managerStrategy?: InputMaybe<ManagerStrategy>;
  marketCapSegment?: InputMaybe<Array<Scalars['Int']['input']>>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<VaultTag>>;
  /** UID of vault to update */
  uid: Scalars['String']['input'];
};

export type VaultActionUnion =
  | GmxActionType
  | KwentaActionDepositType
  | KwentaActionSubmitCloseOrderType
  | KwentaActionSubmitOrderType
  | KwentaActionWithdrawType
  | ZeroXActionType;

export type VaultActions = {
  __typename?: 'VaultActions';
  items: Array<VaultActionUnion>;
  numItems: Scalars['Int']['output'];
  numPages: Scalars['Int']['output'];
};

export type VaultActionsFilterInput = {
  action?: InputMaybe<ExecutorAction>;
  integration?: InputMaybe<ExecutorIntegration>;
};

export type VaultActionsInput = {
  filter?: InputMaybe<VaultActionsFilterInput>;
  page?: InputMaybe<PageInput>;
  vault: Scalars['String']['input'];
};

export type VaultAssetType = {
  __typename?: 'VaultAssetType';
  allocation: Scalars['Decimal']['output'];
  amount: Scalars['Decimal']['output'];
  asset: AssetType;
  avgEntryPrice: Scalars['Decimal']['output'];
  pAndL: Scalars['Decimal']['output'];
  roi: Scalars['Decimal']['output'];
  value: Scalars['Decimal']['output'];
};

export type VaultChainType = {
  __typename?: 'VaultChainType';
  address: Scalars['String']['output'];
  assets: Array<VaultAssetType>;
  aum: Scalars['Decimal']['output'];
  chainId: Scalars['Int']['output'];
  isParent: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type VaultStatsType = {
  __typename?: 'VaultStatsType';
  aum: Scalars['Decimal']['output'];
  aumDataset: Array<AumDataset>;
  depositors: Scalars['Decimal']['output'];
  newDeposits: Scalars['Int']['output'];
  roi?: Maybe<Scalars['Decimal']['output']>;
  roiDataset: Array<RoiDataset>;
  shareMaxPrice: Scalars['Decimal']['output'];
  shareMinPrice: Scalars['Decimal']['output'];
};

export type VaultStatsTypeAumDatasetArgs = {
  dateRange?: InputMaybe<ChartDatasetRange>;
};

export type VaultStatsTypeRoiArgs = {
  dateRange?: InputMaybe<ChartDatasetRange>;
};

export type VaultStatsTypeRoiDatasetArgs = {
  dateRange?: InputMaybe<ChartDatasetRange>;
};

export enum VaultTag {
  Amm = 'AMM',
  Derivatives = 'DERIVATIVES',
  Dex = 'DEX',
  DeFi = 'DE_FI',
  GameFi = 'GAME_FI',
  Governance = 'GOVERNANCE',
  L2 = 'L2',
  LendBorrow = 'LEND_BORROW',
  MemeCoins = 'MEME_COINS',
  Narratives = 'NARRATIVES',
  Nft = 'NFT',
  Rfv = 'RFV',
  Stablecoins = 'STABLECOINS',
  Staking = 'STAKING',
  YieldFarming = 'YIELD_FARMING',
}

export type VaultTransfer = {
  __typename?: 'VaultTransfer';
  account: Scalars['String']['output'];
  action: Scalars['String']['output'];
  amount: Scalars['Decimal']['output'];
  chain: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  transactionHash: Scalars['String']['output'];
};

export type VaultTransfers = {
  __typename?: 'VaultTransfers';
  items: Array<VaultTransfer>;
  numItems: Scalars['Int']['output'];
  numPages: Scalars['Int']['output'];
};

export type VaultTransfersInput = {
  page?: InputMaybe<PageInput>;
  vault: Scalars['String']['input'];
};

export type VaultType = {
  __typename?: 'VaultType';
  assetAllocation: Array<AssetAllocation>;
  assetWatchList: Array<AssetType>;
  assets: Array<VaultAssetType>;
  avatar?: Maybe<Scalars['String']['output']>;
  bridgeLock: Scalars['Boolean']['output'];
  chains: Array<VaultChainType>;
  closed: Scalars['Boolean']['output'];
  cover?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  featured: Scalars['Boolean']['output'];
  fullDescription?: Maybe<Scalars['String']['output']>;
  hidden: Scalars['Boolean']['output'];
  manager: ManagerType;
  managerFocusTime?: Maybe<ManagerFocusTime>;
  managerParticipation?: Maybe<ManagerParticipation>;
  managerPerformanceFee: Scalars['Decimal']['output'];
  managerSentiment?: Maybe<ManagerSentiment>;
  managerStrategy?: Maybe<ManagerStrategy>;
  managerStreamingFee: Scalars['Decimal']['output'];
  marketCapSegment?: Maybe<Array<Scalars['Int']['output']>>;
  name: Scalars['String']['output'];
  pulseUpdatedAt: Scalars['String']['output'];
  riskProfile: RiskProfile;
  shortDescription?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  stats: VaultStatsType;
  symbol: Scalars['String']['output'];
  tags?: Maybe<Array<VaultTag>>;
  uid: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type ViewerType = {
  __typename?: 'ViewerType';
  depositor: DepositorViewType;
  deposits: Array<DepositAndVault>;
  exists: Scalars['Boolean']['output'];
  manager: ManagerViewType;
};

export type WithdrawHistoryType = {
  __typename?: 'WithdrawHistoryType';
  createdAt: Scalars['String']['output'];
  shares: Scalars['Decimal']['output'];
  unitPrice: Scalars['Decimal']['output'];
  withdrawer: Scalars['String']['output'];
};

export type WithdrawUnitType = {
  __typename?: 'WithdrawUnitType';
  deposit: DepositType;
  withdrawHistory: WithdrawHistoryType;
};

export type ZeroXActionType = {
  __typename?: 'ZeroXActionType';
  action: ExecutorAction;
  chain: Scalars['String']['output'];
  chainId: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inputToken: AssetType;
  inputTokenAmount: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  outputToken: AssetType;
  outputTokenAmount: Scalars['String']['output'];
  transaction: Scalars['String']['output'];
  unitPrice: Scalars['String']['output'];
};
